<template>
  <v-data-table
    single-expand
    :options.sync="options"
    :server-items-length="totalItems"
    :headers="headers"
    :items="items"
    :search="search_form"
    item-key="id"
    :item-class="itemRowBackgroundFunc"
    :expanded.sync="expanded"
    :loading="loading"
    :show-expand="showExpand"
    :sort-by.sync="sortBy"
    :sort-desc.sync="sortDesc"
    :footer-props="{
      'items-per-page-options': [10, 20, 30, 40, 50, 100],
    }"
    :dense="dense"
    fixed-header
    height="100vh"
    class="elevation-1"
  >
    <template
      v-slot:top
      v-if="searchEnabled"
    >
      <v-layout class="pa-3">
        <v-text-field
          v-model="search_form"
          label="Vyhľadať"
        />
      </v-layout>
    </template>

    <template
      v-for="slot in Object.keys($scopedSlots)"
      :slot="slot"
      slot-scope="scope"
    >
      <slot
        :name="slot"
        v-bind="scope"
      />
    </template>

    <template
      v-if="!$vuetify.breakpoint.smAndDown"
      v-slot:body.append
    >
      <tr>
        <td v-if="showExpand"></td>

        <td
          v-for="(header, index) in headers"
          v-bind:key="header.value"
        >
          <v-autocomplete
            v-if="'model' in header"
            v-model="autocomplete_model[index]"
            :items="autocomplete_items[index]"
            :search-input.sync="autocomplete_search[index]"
            hide-no-data
            item-text="text"
            item-value="value"
            :label="header.text"
            placeholder="Začnite písať"
            return-object
            clearable
          ></v-autocomplete>
        </td>
      </tr>
    </template>
  </v-data-table>
</template>

<script>
import { APIService } from "@/http/APIService.js";

const apiService = new APIService();

export default {
  name: "DataTablePagingFiltering",

  props: {
    headers: { type: Array, required: true },
    getItemsFunc: { type: Function, required: true },
    itemRowBackgroundFunc: { type: Function, required: true },
    showExpand: { type: Boolean, required: true },
    dense: { type: Boolean, required: true },
    sync: { type: Boolean, required: true },
    searchEnabled: { type: Boolean, required: false, default: true },
    uniqueID: { type: String, required: true }
  },

  data: () => ({
    loading: false,
    loading_properties: false,
    timeout: null,
    parentSlots: null,

    search_form: "",
    totalItems: 0,
    options: {
      itemsPerPage: 50
    },

    items: [],
    expanded: [],
    sortBy: "",
    sortDesc: true,

    autocomplete_model: [],
    autocomplete_items: [],
    autocomplete_loading: [],
    autocomplete_search: [],

    items_per_page: 50,

    dataTableProperties: {
      id: null,
      user: null,
      data_table_id: "",

      page: 1,
      items_per_page: 50,
      sort_by: "",
      sort_desc: "",
      group_by: ""
    }
  }),

  computed: {
  },


  events: {},

  watch: {
    sync() {
      this.getDataFromApi();
    },

    autocomplete_model: {
      handler: function (val, oldVal) {
        this.getDataFromApi();
      },
      deep: true,
    },

    search_form() {
      if (this.timeout) {
        clearTimeout(this.timeout);
      }
      this.timeout = setTimeout(() => {
        this.getDataFromApi();
      }, 500);
    },
    options: {
      handler() {
        if (!this.loading_properties)
          this.getDataFromApi();
      },
    },
    deep: true,
  },

  mounted: function () {
    this.getDataFromApi();

    this.headers.forEach((val, index) => {
      this.autocomplete_model.push("");
      this.autocomplete_items.push([]);
      this.autocomplete_loading.push(false);
      this.autocomplete_search.push("");

      if ("model" in val) {
        this.$watch(["autocomplete_search", index].join("."), (newVal) => {
          this.querySelections(newVal, index);
        });
      }
    });

    // this.getDataTableProperties();
  },

  methods: {
    itemsPerPageUpdate(val) {
      this.options.itemsPerPage = val;
      this.dataTableProperties.items_per_page = this.options.itemsPerPage;
      if (this.dataTableProperties.id) {
        apiService.updateDatatableProperties(this.dataTableProperties).then((response) => {
          this.dataTableProperties = response;
        });
      }

      else {
        this.dataTableProperties.data_table_id = this.uniqueID;

        apiService.createDatatableProperties(this.dataTableProperties).then((response) => {
          this.dataTableProperties = response;
        });

      }
    },

    getDataTableProperties() {

      apiService.getDatatableProperties(this.uniqueID).then((response) => {
        this.loading_properties = true;
        if (response)
          this.dataTableProperties = response;
        this.options.itemsPerPage = this.dataTableProperties.items_per_page;
        this.loading_properties = false;
        this.getDataFromApi();
      });
    },
    querySelections(v, index) {
      // this.autocomplete_loading[index] = true;

      apiService
        .getAutoComplete(this.headers[index].model, v)
        .then((response) => {
          this.autocomplete_items[index] = response;
          // this.autocomplete_loading[index] = false;
        });
    },

    getItems(sort, search, limit, offset, filters) {
      this.loading = true;

      this.getItemsFunc(sort, search, limit, offset, filters).then(
        (response) => {
          this.items = response.results;
          this.totalItems = response.count;
          this.loading = false;

          if (this.items[0] && this.items[0].referendum_questions) {
            for (let question of this.items) {
              question.referendum_questions.sort((a, b) => (a.order_referendum > b.order_referendum) ? 1 : -1)
            }
          }

          if (this.items[0] && this.items[0].question_choose_questions) {
            for (let question of this.items) {
              question.question_choose_questions.sort((a, b) => (a.order_question_choose > b.order_question_choose) ? 1 : -1)
            }
          }
        }
      );
    },

    getDataFromApi() {
      if (this.loading_properties)
        return;
      const { sortBy, sortDesc, page, itemsPerPage } = this.options;

      let sort = "";
      if (sortBy) {
        if (sortBy.length === 1 && sortDesc.length === 1) {
          sort = sortBy[0].replace(/\./g, "__").replace(/_full/g, "");
          if (!sortDesc[0]) sort = "-" + sort;
        }
      }

      let offset = "";
      if (itemsPerPage > 0) {
        offset = (page - 1) * itemsPerPage;
      }

      let filters = [];

      this.headers.forEach((val, index) => {
        if ("model" in val && this.autocomplete_model[index] != null) {
          let model_name = val.value.replace(/\./g, "__");
          filters.push([model_name, this.autocomplete_model[index]]);
        }
      });

      this.getItems(sort, this.search_form, itemsPerPage, offset, filters);
    },
  },
};
</script>